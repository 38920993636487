import { useEffect, useState } from "react";
import { colours } from "components/Charts/Colours";
import { SmallSpinner } from "components/Spinner";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

function CompareSegmentationKey({
  dataCycleId,
  selectedQuestion,
  compareDataCycleId,
  showBenchmarks,
  dataCycleIds,
}: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);
  const [demographicBenchmarks, setDemographicBenchmarks] = useState<any>({});
  const [demographicBenchmarksLoaded, setDemographicBenchmarksLoaded] =
    useState(false);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion && compareDataCycleId) {
      const url = API_URLS.GetSegmentationComparison;

      API.post(url, {
        dataCycleIds: [dataCycleId, compareDataCycleId],
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          setDemographics(response?.data);
        })
        .finally(() => {
          setDemographicsLoaded(true);
        });
    }
  }, [dataCycleId, selectedQuestion, compareDataCycleId]);

  useEffect(() => {
    if (showBenchmarks) {
      setDemographicBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds,
      })
        .then((response: any) => {
          demographicBenchmarks.segmentationQuestion =
            response?.data?.chartTitle;
          demographicBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographicBenchmarks(demographicBenchmarks);
          setDemographicBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showBenchmarks) {
      setDemographicBenchmarks({});
    }
  }, [showBenchmarks, selectedQuestion]);

  return (
    <>
      <div>
        {demographicsLoaded ? (
          <div className="segmentationKeyContainer">
            {demographics.map((item: any) => {
              if (item.codedResponses !== null) {
                return (
                  <div className="segmentationKeyContainer">
                    <p>{item.dataCycleName}</p>
                    {item.codedResponses.map((responses: any, index: any) => {
                      return (
                        <div className="segmentationKeyLine">
                          <div
                            className="segmentationKeyColour"
                            style={{ backgroundColor: colours[index] }}
                          />
                          <div className="segmentationKeyText">{`${responses.keypadDesription}: ${responses.participantsCount} (${responses.participantsPercentage}%)`}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <div style={{ width: "150px", height: "150px" }}>
            <SmallSpinner />
          </div>
        )}
      </div>
      {showBenchmarks && (
        <div className="benchmarkKey">
          <h3>Benchmark</h3>
          {demographicBenchmarks.responses != null &&
          demographicBenchmarks.responses.length > 0 ? (
            <div className="segmentationKeyContainer">
              {demographicBenchmarks.responses.map((item: any, index: any) => {
                return (
                  <div className="segmentationKeyLine">
                    <div
                      className="segmentationKeyColour"
                      style={{ backgroundColor: colours[index] }}
                    />
                    <div className="segmentationKeyText">{`${item.responseOption}: ${item.participantsCount} (${item.percentage}%)`}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ width: "150px", height: "150px" }}>
              <SmallSpinner />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CompareSegmentationKey;
