import { useNavigate } from "react-router-dom";
import { UseTable } from "hooks/useTable";
import { ISiteList } from "models/Interfaces/IReports";
import { ReportingTypeEnum } from "models/Enum/EnumTypes";

export default function SiteList({
  sites,
  clientId,
  selectedOrgId,
  avgSiteScore,
  benchmarksEnabled,
}: ISiteList) {
  const navigate = useNavigate();

  const columns = [
    {
      title: "ORGANISATION",
      field: "organisationName",
    },
    {
      title: "TYPE",
      field: "surveyTemplateTypeName",
    },
    {
      title: "WORKPLACE SCORE",
      field: "siteRating",
      render: (rowData: any) => (
        <div>
          <p>{rowData.siteRating < 0 ? "N/A" : rowData.siteRating}</p>
        </div>
      ),
    },
    {
      title: "WORKPLACE",
      field: "organisationSiteName",
    },
    {
      title: "RESULTS",
      field: "organisationSiteId",
      render: (rowData: any) => (
        <div className="actions-container">
          {rowData.siteRating !== null && rowData.siteRating !== -2 && (
            <p
              onClick={() =>
                navigateToReports(
                  rowData?.organisationId,
                  rowData?.organisationSiteId,
                  rowData?.surveyTemplateTypeId,
                )
              }
            >
              View
            </p>
          )}
        </div>
      ),
    },
  ];

  const navigateToReports = (
    organisationId: string,
    siteId: string,
    surveyTemplateTypeId: string,
  ) => {
    navigate(
      `/reports/tabs/${organisationId}/${siteId}/${surveyTemplateTypeId}/${ReportingTypeEnum.Priorities}/${clientId}/${selectedOrgId}`,
    );
  };

  return (
    <div>
      {sites.length > 0 && (
        <UseTable
          columns={columns}
          data={sites}
          title={
            benchmarksEnabled && avgSiteScore
              ? `Average site score: ${avgSiteScore}`
              : ``
          }
        />
      )}
    </div>
  );
}
