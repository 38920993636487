import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { IKey } from "models/Interfaces/ICharts";
import CompareSegmentationKey from "./CompareSegmentationKey";
import PrioritiesAndDetailedKey from "./PrioritiesAndDetailedKey";
import ResponseRatesKey from "./ResponseRatesKey";
import SegmentationKey from "./SegmentationKey";
import "./Style.css";

function Key({
  tab,
  selectedQuestion,
  dataCycleId,
  compareDataCycleId,
  showBenchmarks,
  dataCycleIds,
}: IKey) {
  return (
    <div>
      {tab === ReportingTypeEnum.Priorities ||
      tab === ReportingTypeEnum.Result ||
      tab === ReportingTypeEnum.Progress ? (
        <PrioritiesAndDetailedKey />
      ) : selectedQuestion.questionId === undefined ? (
        <ResponseRatesKey />
      ) : compareDataCycleId !== "" ? (
        <CompareSegmentationKey
          selectedQuestion={selectedQuestion}
          dataCycleId={dataCycleId}
          compareDataCycleId={compareDataCycleId}
          showBenchmarks={showBenchmarks}
          dataCycleIds={dataCycleIds}
        />
      ) : (
        <SegmentationKey
          selectedQuestion={selectedQuestion}
          dataCycleId={dataCycleId}
          showBenchmarks={showBenchmarks}
          dataCycleIds={dataCycleIds}
        />
      )}
    </div>
  );
}

export default Key;
