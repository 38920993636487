import "./Style.css";

function AvgSiteScore(params: any) {
  return (
    <div className="benchmarkBox">
      <div className={`benchmarkBoxBanner`}>
        <div>
          <p style={{ display: "inline-block", paddingLeft: "5px" }}>
            Average site score
          </p>
        </div>
        <div
          className={`avgSiteScoreCircle ${
            params.avgSiteScore < 0
              ? "scoreZero"
              : params.avgSiteScore >= 0 && params.avgSiteScore <= 5
              ? "scoreLow"
              : params.avgSiteScore > 5 && params.avgSiteScore <= 7.5
              ? "scoreMedium"
              : "scoreHigh"
          }`}
        >
          {params.avgSiteScore < 0 ? "N/A" : params.avgSiteScore}
        </div>
      </div>
    </div>
  );
}

export default AvgSiteScore;
