import { SmallSpinner } from "components/Spinner";
import "./Style.css";
import CheckBox from "components/Controls/CheckBox";

function BenchmarkBox(params: any) {
  return (
    <div className="benchmarkBox">
      {params.avgParticipation == null || params.avgSiteScore == null ? (
        <div className="spinnerContainer">
          <SmallSpinner />
        </div>
      ) : (
        <>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                Average site score
              </p>
            </div>
            <div
              className={`avgSiteScoreCircle ${
                params.avgSiteScore < 0
                  ? "scoreZero"
                  : params.avgSiteScore >= 0 && params.avgSiteScore <= 5
                  ? "scoreLow"
                  : params.avgSiteScore > 5 && params.avgSiteScore <= 7.5
                  ? "scoreMedium"
                  : "scoreHigh"
              }`}
            >
              {params.avgSiteScore < 0 ? "N/A" : params.avgSiteScore}
            </div>
          </div>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                The average participation rate for your sites is{" "}
                {`${params.avgParticipation}%`}.
              </p>
            </div>
          </div>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <CheckBox
                id="enableBenchmarks"
                label="Enable benchmarks?"
                name="enableBenchmarks"
                value={params.benchmarksEnabled}
                onChange={params.toggleBenchmarks}
                error={""}
                checked={params.benchmarksEnabled}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BenchmarkBox;
