/* eslint-disable import/order */
import { useEffect, useState } from "react";
import { API_URLS } from "../../../../config/ApiUrls";
import API from "../../../../services/Api.Service";
import { useAuth } from "lib/auth";
import PrioritiesAndDetailed from "../PrioritiesAndDetailed";
import PrioritiesAndDetailedComparison from "../PrioritiesAndDetailedComparison";
import Participation from "../Participation";
import Filters from "../Filters";
import WhatToLookFor from "../WhatToLookFor";
import BenchmarkBox from "../BenchmarkBox";
import SiteScore from "../SiteScore";
import Key from "../../../../components/Charts/Key";
import "./Style.css";
import { ITabDetailProps } from "../../../../models/Interfaces/IMapSite";
import {
  ParticipantEnum,
  ReportingTypeEnum,
  StatusBadges,
} from "../../../../models/Enum/EnumTypes";
import { faDownload, faCodeCompare } from "@fortawesome/free-solid-svg-icons";
import ParticipationChartSelect from "../ParticipationChartSelect";
import { ISelectedQuestionProps } from "models/Interfaces/IReports";
import { useLoading } from "providers/loading";
import Controls from "components/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Progress from "../Progress";
import DownloadModal from "../DownloadModal";
import Improvements from "../Improvements";
import StatusBadgeInfo from "../Improvements/StatusBadgeInfo";

function TabbedView({
  TabName,
  siteId,
  organisationId,
  surveyTemplateTypeId,
  clientId,
  selectedOrgId,
}: ITabDetailProps) {
  // const { setAppLoading } = useLoading();
  const [sites, setSites] = useState<any>([]);
  const [dataCycles, setDataCycles] = useState<any>([]);
  const [dataCycleIdsForBenchmarks, setDataCycleIdsForBenchmarks] =
    useState<any>([]);
  const [compareDataCycles, setCompareDataCycles] = useState<any>([]);
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any>([]);
  const [selectedSiteId, setSelectedSiteId] = useState(siteId);
  const [dataCycleId, setDataCycleId] = useState("");
  const [selectedDataCycleName, setSelectedDataCycleName] = useState("");
  const [compareDataCycleId, setCompareDataCycleId] = useState("");
  const [avgSiteScore, setAvgSiteScore] = useState(null);
  const [avgParticipation, setAvgParticipation] = useState(null);
  const [benchmarksEnabled, setBenchmarksEnabled] = useState(false);
  const [selectedSurveyTemplateTypeId, setSelectedSurveyTemplateTypeId] =
    useState(surveyTemplateTypeId);
  const [selectedQuestion, setSelectedQuestion] =
    useState<ISelectedQuestionProps>({
      responseChartType: ParticipantEnum.BarChart,
    });
  const [selectedCompareDataCycleName, setSelectedCompareDataCycleName] =
    useState("");
  const [segmentationFiltersForReports, setSegmentationFiltersForReports] =
    useState<any>([]);
  const [modal, setModal] = useState(false);
  const [statusBadge, setStatusBadge] = useState(StatusBadges.None);
  const [statusBadgeRenderInfo, setStatusBadgeRenderInfo] = useState({
    none: true,
    listening: false,
    responding: false,
    improving: false,
  });
  const [isFiltersLoadedForReports, setFiltersLoadedForReports] =
    useState(false);
  const [hideSegmentation, setHideSegmentation] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);
  const [compareHideFilters, setCompareHideFilters] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    // setAppLoading(true);
    API.get(API_URLS.GetSurveyTemplateTypesForReportScreen, {
      params: {
        organisationSiteId: selectedSiteId,
      },
    })
      .then((response: any) => {
        const templateTypeOptions = response.data.data.map((x: any) => {
          return {
            key: x.surveyTemplateTypeId,
            label: x.surveyTemplateTypeName,
          };
        });
        setSurveyTemplateTypes(templateTypeOptions);
        const currentTemplateType = templateTypeOptions.find(
          (x: any) => x.key === surveyTemplateTypeId,
        );
        if (currentTemplateType) {
          setSelectedSurveyTemplateTypeId(currentTemplateType.key);
        }
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    API.get(API_URLS.GetSitesForMap, {
      params: {
        organisationId: selectedOrgId,
        clientId,
        surveyTemplateTypeId,
      },
    })
      .then((response: any) => {
        const siteOptions = response.data.data
          .filter((x: any) => x.siteRating !== -2)
          .map((x: any) => {
            return {
              key: x.organisationSiteId,
              label: x.organisationSiteName,
            };
          });
        const dataCycleIdsForBenchmarking = response.data.data.map(
          (x: any) => x.dataCycleId,
        );
        setSites(siteOptions);
        const currentSite = siteOptions.find((x: any) => x.key === siteId);
        setSelectedSiteId(currentSite.key);
        setAvgSiteScore(response.data.averageSiteScore);
        setDataCycleIdsForBenchmarks(dataCycleIdsForBenchmarking);
      })
      .finally(() => {});
  }, [selectedSurveyTemplateTypeId]);

  useEffect(() => {
    // setAppLoading(true);
    // Updated this useEffect call to only run when sites has been populated with data
    if (selectedSiteId && selectedSurveyTemplateTypeId && sites.length > 0) {
      API.get(API_URLS.GetDataCyclesForReportScreen, {
        params: {
          organisationSiteId: selectedSiteId,
          surveyTemplateTypeId: selectedSurveyTemplateTypeId,
        },
      })
        .then((response: any) => {
          const dataCycleOptions = response.data.data
            .filter(
              (data: any) =>
                data.surveyTemplateTypeId === selectedSurveyTemplateTypeId,
            )
            .map((x: any) => {
              return {
                key: x.dataCycleId,
                label: x.dataCycleName,
                hideCharts: x.hideSegmentationChart,
                hideFilters: x.hideFilters,
              };
            });
          setHideSegmentation(dataCycleOptions[0].hideCharts);
          setHideFilters(dataCycleOptions[0].hideFilters);
          setDataCycles(dataCycleOptions);
          setCompareDataCycles(
            dataCycleOptions.slice(1, dataCycleOptions.length),
          );
          setDataCycleId(dataCycleOptions[0].key);
          setSelectedDataCycleName(dataCycleOptions[0].label);
          setCompareDataCycleId("");
          setSelectedCompareDataCycleName("");
        })
        .finally(() => {});

      API.post(API_URLS.GetStatusBadges, {
        SiteId: selectedSiteId,
        SurveyTemplateTypeId: selectedSurveyTemplateTypeId,
      })
        .then((response: any) => {
          setStatusBadgeRenderInfo(response.data);
        })
        .finally(() => {});
    }
  }, [selectedSiteId, selectedSurveyTemplateTypeId, JSON.stringify(sites)]);

  useEffect(() => {
    // setAppLoading(true);
    // Updated this useEffect call to only run when sites has been populated with data
    if (dataCycleIdsForBenchmarks.length > 0) {
      API.post(API_URLS.GetAverageResponseRates, {
        dataCycleIds: dataCycleIdsForBenchmarks,
      })
        .then((response: any) => {
          setAvgParticipation(response.data.numberOfCompleteWorkersPerc);
        })
        .finally(() => {});
    }
  }, [dataCycleIdsForBenchmarks]);

  const handleSelectSite = (event: any) => {
    setCompareDataCycleId("");
    setSelectedSiteId(event.target.value);
  };

  const handleSelectSurveyTemplate = (event: any) => {
    setSelectedSurveyTemplateTypeId(event.target.value);
  };

  const handleSelectDataCycle = (event: any) => {
    setDataCycleId(event.target.value);

    const cycle = dataCycles.find(
      (dc: { key: any }) => dc.key === event.target.value,
    );

    const hideCharts = cycle?.hideCharts;
    const filters = cycle?.hideFilters;

    setSelectedDataCycleName(cycle?.label);

    if (compareDataCycleId === cycle?.key) {
      setCompareDataCycleId("");
      setSelectedCompareDataCycleName("");
    }

    setCompareDataCycles(dataCycles.filter((dc: any) => dc.key !== cycle?.key));
    setHideSegmentation(hideCharts);
    setHideFilters(filters);
  };

  const handleSelectCompareDataCycle = (selectedObject: any) => {
    if (
      TabName === ReportingTypeEnum.Priorities ||
      TabName === ReportingTypeEnum.Result
    ) {
      setFiltersLoadedForReports(false);
    }
    setCompareHideFilters(selectedObject.hideFilters);
    setCompareDataCycleId(selectedObject.key);
    setSelectedCompareDataCycleName(selectedObject.label);
  };

  const handleSelectCompareDataCycleTextInputChange = (text: any) => {
    setSelectedCompareDataCycleName(text);
    if (text === "") {
      setCompareDataCycleId("");
    } else {
      const selectedObject = compareDataCycles.find((obj: any) => {
        return obj.label === text;
      });
      if (selectedObject !== undefined) {
        handleSelectCompareDataCycle(selectedObject);
      }
    }
  };

  const hideAndShowModal = () => {
    setModal(!modal);
  };

  const toggleBenchmarks = () => {
    setBenchmarksEnabled(!benchmarksEnabled);
  };

  return (
    <div className="tabDetails">
      <div className="chartSection">
        <div className="accordianHolder left">
          {auth?.user?.data?.benchmarksEnabled && (
            <BenchmarkBox
              avgSiteScore={avgSiteScore}
              avgParticipation={avgParticipation}
              benchmarksEnabled={benchmarksEnabled}
              toggleBenchmarks={toggleBenchmarks}
            />
          )}
          <div className="workplace-selector">
            <SiteScore
              siteId={selectedSiteId}
              surveyTemplateTypeId={selectedSurveyTemplateTypeId}
              segFilters={[]}
              dataCycleId={dataCycleId}
            />
            <Controls.Select
              id="siteId"
              name="siteId"
              // label="Workplace*"
              label=""
              value={selectedSiteId}
              // inputValue={selectedSiteName}
              onChange={handleSelectSite}
              // onInputChange={handleSelectSiteTextInputChange}
              options={sites}
              variant="outlined"
            />
          </div>
          <div className="template-type-selector">
            <Controls.Select
              id="surveyTemplateTypeId"
              name="surveyTemplateTypeId"
              // label="Survey template type*"
              label=""
              value={selectedSurveyTemplateTypeId}
              // inputValue={surveyTemplateTypeName}
              onChange={handleSelectSurveyTemplate}
              // onInputChange={handleSelectSurveyTemplateTextInputChange}
              options={surveyTemplateTypes}
              variant="outlined"
            />
          </div>
          {TabName === ReportingTypeEnum.Participation ? (
            <ParticipationChartSelect
              dataCycleId={dataCycleId}
              compareDataCycleId={compareDataCycleId}
              setSelectedQuestion={setSelectedQuestion}
              hideSegmentation={hideSegmentation}
            />
          ) : TabName === ReportingTypeEnum.Priorities ||
            TabName === ReportingTypeEnum.Result ? (
            <Filters
              dataCycleId={dataCycleId}
              compareDataCycleId={compareDataCycleId}
              setFiltersLoadedForReports={setFiltersLoadedForReports}
              setSegmentationFiltersForReports={
                setSegmentationFiltersForReports
              }
              hideFilter={hideFilters || compareHideFilters}
            />
          ) : TabName === ReportingTypeEnum.Improvements ? (
            <StatusBadgeInfo status={statusBadge} />
          ) : null}
          <WhatToLookFor tabName={TabName} />
        </div>
        <div className="chartHolder">
          <div className="topControl">
            {TabName === ReportingTypeEnum.Priorities ||
            TabName === ReportingTypeEnum.Result ||
            TabName === ReportingTypeEnum.Progress ? (
              <div className="charts-reports-header">{TabName}</div>
            ) : TabName === ReportingTypeEnum.Participation &&
              selectedQuestion ? (
              <div className="charts-reports-header">
                {selectedQuestion.questionText || "Response Rates"}
              </div>
            ) : null}
            <div className="accordianWrapper">
              <div
                className={`selector ${
                  TabName === ReportingTypeEnum.Progress ||
                  TabName === ReportingTypeEnum.Improvements ||
                  (TabName === ReportingTypeEnum.Participation &&
                    selectedQuestion.questionText === undefined)
                    ? "hidden"
                    : ""
                }`}
              >
                <Controls.Select
                  id="dataCycleId"
                  name="dataCycleId"
                  label="Survey cycle*"
                  value={dataCycleId}
                  // inputValue={selectedDataCycleName}
                  onChange={handleSelectDataCycle}
                  // onInputChange={handleSelectDataCycleTextInputChange}
                  options={dataCycles}
                />
              </div>
              {TabName !== ReportingTypeEnum.Improvements &&
                TabName !== ReportingTypeEnum.Progress &&
                TabName !== ReportingTypeEnum.Participation && (
                  <>
                    <div className="compareIcon">
                      <FontAwesomeIcon icon={faCodeCompare} />
                    </div>
                    <div className="selector">
                      <Controls.AutoComplete
                        id="compareDataCycleId"
                        name="compareDataCycleId"
                        label="Compare survey cycle"
                        value={compareDataCycleId}
                        inputValue={selectedCompareDataCycleName}
                        onChange={handleSelectCompareDataCycle}
                        onInputChange={
                          handleSelectCompareDataCycleTextInputChange
                        }
                        options={compareDataCycles}
                      />
                    </div>
                  </>
                )}
              {TabName === ReportingTypeEnum.Participation &&
                selectedQuestion.questionText !== undefined && (
                  <>
                    <div className="compareIcon">
                      <FontAwesomeIcon icon={faCodeCompare} />
                    </div>
                    <div className="selector">
                      <Controls.AutoComplete
                        id="compareDataCycleId"
                        name="compareDataCycleId"
                        label="Compare survey cycle"
                        value={compareDataCycleId}
                        inputValue={selectedCompareDataCycleName}
                        onChange={handleSelectCompareDataCycle}
                        onInputChange={
                          handleSelectCompareDataCycleTextInputChange
                        }
                        options={compareDataCycles}
                      />
                    </div>
                  </>
                )}
            </div>
            <div>
              <div
                className={`downloadButton ${
                  TabName === ReportingTypeEnum.Progress ||
                  TabName === ReportingTypeEnum.Improvements
                    ? "hidden"
                    : ""
                }`}
                onClick={hideAndShowModal}
              >
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
          </div>
          <div className="charts-content">
            {siteId &&
              (TabName === ReportingTypeEnum.Priorities ||
              TabName === ReportingTypeEnum.Result ? (
                compareDataCycleId === "" ? (
                  <div className="charts-Header">
                    <PrioritiesAndDetailed
                      tab={TabName}
                      dataCycleId={dataCycleId}
                      filters={segmentationFiltersForReports}
                      dataCycleIds={dataCycleIdsForBenchmarks}
                      showBenchmarks={benchmarksEnabled}
                    />
                  </div>
                ) : (
                  <div className="charts-Header">
                    <PrioritiesAndDetailedComparison
                      tab={TabName}
                      dataCycleId={dataCycleId}
                      filters={segmentationFiltersForReports}
                      compareDataCycleId={compareDataCycleId}
                      showBenchmarks={benchmarksEnabled}
                      dataCycleIds={dataCycleIdsForBenchmarks}
                    />
                  </div>
                )
              ) : TabName === ReportingTypeEnum.Participation ? (
                <div className="charts-Header">
                  <Participation
                    selectedQuestion={selectedQuestion}
                    siteId={selectedSiteId}
                    surveyTemplateTypeId={selectedSurveyTemplateTypeId}
                    dataCycleId={dataCycleId}
                    dataCycleName={selectedDataCycleName}
                    compareDataCycleId={compareDataCycleId}
                    selectResponseRates={true}
                    dataCycleIds={dataCycleIdsForBenchmarks}
                    showBenchmarks={benchmarksEnabled}
                    clientId={clientId}
                    organisationId={organisationId}
                  />
                </div>
              ) : TabName === ReportingTypeEnum.Progress ? (
                <div className="charts-Header">
                  <Progress
                    siteId={selectedSiteId}
                    surveyTemplateTypeId={surveyTemplateTypeId}
                  />
                </div>
              ) : TabName === ReportingTypeEnum.Improvements ? (
                <div className="charts-Header">
                  <Improvements
                    setStatusBadge={setStatusBadge}
                    renderDetails={statusBadgeRenderInfo}
                  />
                </div>
              ) : null)}
          </div>
        </div>
        <div className="accordianHolder right small">
          {TabName !== ReportingTypeEnum.Improvements && (
            <Key
              tab={TabName}
              selectedQuestion={selectedQuestion}
              dataCycleId={dataCycleId}
              compareDataCycleId={compareDataCycleId}
              dataCycleIds={dataCycleIdsForBenchmarks}
              showBenchmarks={benchmarksEnabled}
            />
          )}
        </div>
      </div>
      {modal && (
        <DownloadModal
          setModal={hideAndShowModal}
          items={dataCycles}
          reportType={TabName}
          selectedQuestion={selectedQuestion}
        />
      )}
    </div>
  );
}

export default TabbedView;
