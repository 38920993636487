import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { IResultsChart } from "models/Interfaces/ICharts";
import API from "services/Api.Service";
import { SmallSpinner } from "components/Spinner";
import "./Style.css";

function PrioritiesAndDetailed({
  tab,
  dataCycleId,
  filters,
  showBenchmarks,
  dataCycleIds,
}: IResultsChart) {
  const [results, setResults] = useState<any>([]);
  const [benchmarks, setBenchmarks] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [demographicBenchmarksLoading, setDemographicBenchmarksLoading] =
    useState(false);

  useEffect(() => {
    setLoading(true);
    if (tab && dataCycleId && filters && dataCycleIds) {
      const url =
        tab === ReportingTypeEnum.Priorities
          ? API_URLS.GetSitePriorities
          : API_URLS.GetAllResults;

      API.post(url, {
        dataCycleId,
        segmentationFilters: filters,
      })
        .then((response: any) => {
          const resultsReturned = response?.data?.dataCycleQuestionResults.map(
            (x: any) => {
              return {
                question: x.description,
                questionId: x.questionId,
                positivePercentage: x.positivePercentage,
                neutralPercentage: x.neutralPercentage,
                negativePercentage: x.negativePercentage,
              };
            },
          );
          setResults(resultsReturned);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tab, dataCycleId, JSON.stringify(filters)]);

  useEffect(() => {
    if (showBenchmarks && dataCycleIds) {
      setDemographicBenchmarksLoading(true);
      API.post(API_URLS.GetDetailedResultsBenchmarking, {
        dataCycleId,
        segmentationFilters: filters,
        dataCycleIds,
      })
        .then((response: any) => {
          const benchmarksReturned = response?.data?.map((x: any) => {
            return {
              question: x.description,
              questionId: x.questionId,
              positivePercentage: x.positivePercentage,
              neutralPercentage: x.neutralPercentage,
              negativePercentage: x.negativePercentage,
              surveyCycleName: x.surveyCycleName,
            };
          });
          setBenchmarks(benchmarksReturned);
        })
        .finally(() => {
          setDemographicBenchmarksLoading(false);
        });
    }
    if (!showBenchmarks) {
      setBenchmarks([]);
    }
  }, [showBenchmarks, dataCycleIds]);

  return loading || demographicBenchmarksLoading ? (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  ) : results.length > 0 ? (
    <Charts.HorizontalBarChart items={results} benchmarks={benchmarks} />
  ) : (
    <h2 className="text-center">
      {tab === ReportingTypeEnum.Priorities
        ? "This workplace has no priorities for the currently selected survey cycle and filters."
        : "This workplace has no results for the currently selected survey cycle and filters."}
    </h2>
  );
}

export default PrioritiesAndDetailed;
